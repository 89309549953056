/* global DYO */
import { useEffect, useState } from 'react';
import { useClientSideABTest } from '../abtest/components/abtest';

export const transformDYProductRecs = resp => {
    try {
        const { slots, wId, fId } = resp || {};
        const dyContainerTracking = { 'data-dy-widget-id': wId, 'data-dy-feed-id': fId };
        const productRecs = slots.map(s => {
            const { item, strId } = s;
            const { sku } = item;
            return {
                ...item,
                catentryId: item.catid,
                images: [{ image: item.image_url }],
                productUrl: item.url,
                dyItemTracking: { 'data-dy-product-id': sku, 'data-dy-strategy-id': strId },
                ctaViewDetailsLink: item.url,
            };
        });
        return { productRecs, dyContainerTracking };
    } catch (e) {
        return { productRecs: [], dyContainerTracking: {} };
    }
};

const getDY = () => {
    try {
        return window.DYO;
    } catch (e) {
        return undefined;
    }
};

const useDynamicYieldRecs = (groupId, componentKey, options, transform, widgetRef) => {
    const [recs, setRecs] = useState({});
    const stateKey = `${groupId}_${componentKey}`;
    const { current: widgetElement } = widgetRef || {};

    const dy = getDY();

    useEffect(() => {
        if (widgetElement && widgetElement.dataset.dyWidgetId) {
            try {
                DYO.recommendations.registerElements(widgetElement);
            } catch (e) {}
        }
    }, [widgetElement]);
    useEffect(() => {
        //if no groupId is passed do nothing
        //if using a stateKey that already has data do nothing
        if (!DYO || !groupId || stateKey in recs) return;

        try {
            setRecs({ ...recs, [stateKey]: { fetching: true } });
            DYO.recommendationWidgetData(groupId, options, (error, data) => {
                if (error) {
                    setRecs({ ...recs, [stateKey]: { error, fetching: false } });
                    return;
                }
                setRecs({ ...recs, [stateKey]: { ...data, fetching: false } });
            });
        } catch (e) {}
        //if you add options as a dependancy, it will cause an infinite loop unless you memoize the options object
    }, [stateKey, dy]);

    return transform ? transformDYProductRecs(recs[stateKey]) : recs[stateKey];
};
export default useDynamicYieldRecs;

/**
 * Returns sorted products first the first n products in the list based on the size
 * the remaining products are returned in the order they are passed.
 * @param {*} groupId - Strategy ID
 * @param {*} componentKey - Unique key for the component
 * @param {*} products - List of products to be sorted
 * @param {*} size - Number of products to sort
 * @returns
 */
export const useDynamicYieldSort = (groupId, componentKey, products, size = 10) => {
    try {
        //if groupId is prefixed with 'ab:' then use the abTest hook to get the groupId
        const abTestId = typeof groupId === 'string' ? groupId.split('ab:')[1] : null;
        const { groupId: abTestGroupId } = useClientSideABTest(abTestId) || {};
        const resolvedGroupId = abTestId ? abTestGroupId : groupId;
        //pass null groupId to avoid calling DY if no products are passed
        const { slots } =
            useDynamicYieldRecs(!products || !products.length ? null : resolvedGroupId, componentKey, {
                realtimeRules: [
                    {
                        id: -1,
                        query: {
                            conditions: [
                                {
                                    field: 'group_id',
                                    arguments: products.slice(0, size).map(s => ({
                                        action: 'IS',
                                        value: s.sku,
                                    })),
                                },
                            ],
                        },
                        type: 'include',
                        slots: [],
                    },
                ],
            }) || {};

        if (Array.isArray(slots)) {
            //returns products in the order they are returned from DY
            const sortedList = products
                .slice()
                .sort(
                    (a, b) => slots.findIndex(p => p.item.sku === a.sku) - slots.findIndex(p => p.item.sku === b.sku),
                );
            return { products: sortedList, loading: false };
        }

        return { products, loading: true };
    } catch (e) {
        return { products, loading: false };
    }
};
